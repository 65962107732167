.map-wrap {
  position: relative;
  top: 50px;
  width: 100%;
  /* height: 100vh; */
  height: calc(100vh - 350px);
  /* calculate height of the screen minus the heading */
}

.map {
  position: absolute;
  width: 100%;
  height: 100%;
}

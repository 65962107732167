.legend {
  text-align: left;

  & > section {
    display: grid;
    grid-template-columns: 30px 1fr;
    column-gap: 10px;
    row-gap: 10px;

    & > div {
      display: flex;
      align-items: center;
      width: 100%;
      height: 100%;

      &.gradient-container {
        justify-content: center;
        font-size: 0.6rem;

        & > .ticks {
          margin-left: 2px;
          display: flex;
          height: 100%;
          flex-direction: column;
          justify-content: space-between;
        }
      }
    }

    img {
      width: 100%;
    }
  }
}
